<template>
  <div>
    <b-row class="">
      <b-col md="4">
        <b-card
          no-body
          class="card__"
        >
          <b-card-header class="p-1">
            PEDIDOS DEL DÌA
          </b-card-header>
          <div v-if="isBusy">
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-50" />
              <strong>Cargando...</strong>
            </div>
          </div>
          <div
            v-else
            class="div__ d-flex align-items-center justify-content-center"
          >
            <div v-if="series[0].data.length > 0 || series[1].data.length > 0">
              <div
                id="chart"
                class="p-1"
              >
                <apexchart
                  type="bar"
                  height="200"
                  :options="chartOptions"
                  :series="series"
                />
              </div>
            </div>
            <div
              v-else
              class="box__"
            >
              <div>
                No existe pedidos programados
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col md="4">
        <b-card
          no-body
          class="card__"
        >
          <b-card-header class="p-1">
            MENU DEL DÌA
          </b-card-header>
          <div v-if="isBusy">
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-50" />
              <strong>Cargando...</strong>
            </div>
          </div>
          <div
            v-else
            class="div__ d-flex align-items-center justify-content-center"
          >
            <div v-if="series2.length > 0">
              <div id="chart">
                <apexchart
                  type="donut"
                  width="300"
                  height="242"
                  :options="chartOptions2"
                  :series="series2"
                />
              </div>
            </div>
            <div
              v-else
              class="box__"
            >
              <div>
                No existe menú programado
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col md="4">
        <b-card
          no-body
          class="card__"
        >
          <b-card-header class="p-1">
            TIPO DE DIETAS
          </b-card-header>
          <div v-if="isBusy">
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-50" />
              <strong>Cargando...</strong>
            </div>
          </div>
          <div
            v-else
            class="div__ d-flex align-items-center justify-content-center"
          >
            <div v-if="series3.length > 0">
              <div id="chart">
                <apexchart
                  type="donut"
                  width="300"
                  height="242"
                  :options="chartOptions3"
                  :series="series3"
                />
              </div>
            </div>
            <div
              v-else
              class="box__"
            >
              <div>
                No existe menú programado
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
	BCard,
	BCardHeader,
	BRow,
	BCol,
	BSpinner,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
		BRow,
		BCol,
		BCard,
		BCardHeader,
		BSpinner,
		apexchart: VueApexCharts,
	},
  props: {
    series: {
      type: Array,
      default: null,
    },
    series2: {
      type: Array,
      default: null,
    },
    series3: {
      type: Array,
      default: null,
    },
    labels: {
      type: Array,
      default: null,
    },
		isBusy: {
			type: Boolean,
			default: null,
		},
  },
  data() {
    return {
      chartOptions: {
				chart: {
					type: 'bar',
					height: 200,
					toolbar: {
						show: false,
					},
				},
				plotOptions: {
					bar: {
						horizontal: false,
						columnWidth: '60%',
						endingShape: 'rounded',
					},
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					show: true,
					width: 2,
					colors: ['transparent'],
				},
				xaxis: {
					categories: ['Desayunos', 'Almuerzos', 'Cenas'],
				},
				yaxis: {
					title: {
						text: '',
					},
				},
				fill: {
					opacity: 1,
				},
				tooltip: {
					/* y: {
						formatter: function (val) {
							return '$ ' + val + ' thousands'
						},
					}, */
				},
			},
			chartOptions2: {
				chart: {
					type: 'donut',
				},
				legend: {
					show: false,
				},
				dataLabels: {
					formatter: (val, { seriesIndex, dataPointIndex, w }) =>
						w.config.series[seriesIndex],
					textAnchor: 'middle',
					distributed: false,
					offsetX: 0,
					offsetY: 0,
					style: {
						fontSize: '14px',
						fontFamily: 'Helvetica, Arial, sans-serif',
						fontWeight: 'bold',
						colors: undefined,
					},
					background: {
						enabled: true,
						foreColor: '#fff',
						padding: 4,
						borderRadius: 2,
						borderWidth: 1,
						borderColor: '#fff',
						opacity: 0.9,
						dropShadow: {
							enabled: false,
							top: 1,
							left: 1,
							blur: 1,
							color: '#000',
							opacity: 0.45,
						},
					},
					dropShadow: {
						enabled: false,
						top: 1,
						left: 1,
						blur: 1,
						color: '#000',
						opacity: 0.45,
					},
				},
				plotOptions: {
					pie: {
						donut: {
							labels: {
								show: true,
								total: {
									show: true,
									showAlways: true,
									label: 'Total',
									fontSize: '22px',
									fontWeight: 900,
									color: '#373d3f',
									formatter(w) {
										return w.globals.seriesTotals.reduce((a, b) => a + b, 0)
									},
								},
							},
						},
					},
				},
				labels: ['Desayunos', 'Almuerzos', 'Cenas'],
			},
			chartOptions3: {
				chart: {
					type: 'donut',
				},
				legend: {
					show: false,
				},
				dataLabels: {
					formatter: (val, { seriesIndex, dataPointIndex, w }) =>
						w.config.series[seriesIndex],
					textAnchor: 'middle',
					distributed: false,
					offsetX: 0,
					offsetY: 0,
					style: {
						fontSize: '14px',
						fontFamily: 'Helvetica, Arial, sans-serif',
						fontWeight: 'bold',
						colors: undefined,
					},
					background: {
						enabled: true,
						foreColor: '#fff',
						padding: 4,
						borderRadius: 2,
						borderWidth: 1,
						borderColor: '#fff',
						opacity: 0.9,
						dropShadow: {
							enabled: false,
							top: 1,
							left: 1,
							blur: 1,
							color: '#000',
							opacity: 0.45,
						},
					},
					dropShadow: {
						enabled: false,
						top: 1,
						left: 1,
						blur: 1,
						color: '#000',
						opacity: 0.45,
					},
				},
				plotOptions: {
					pie: {
						donut: {
							labels: {
								show: true,
								total: {
									show: true,
									showAlways: true,
									label: 'Total',
									fontSize: '22px',
									fontWeight: 900,
									color: '#373d3f',
									formatter(w) {
										return w.globals.seriesTotals.reduce((a, b) => a + b, 0)
									},
								},
							},
						},
					},
				},
				labels: this.labels,
			},
    }
  },
	watch: {
		labels(newData) {
			const me = this
			me.chartOptions3.labels = newData
		},
	},
}
</script>

<style lang="scss" scoped>
  .card__ {
    box-shadow: 0 5px 11px 0 rgba(145, 144, 144, 0.3), 0 4px 15px 0 rgba(133, 132, 132, 0.15);
  }

	.div__ {
		height: 242px;
	}

	.box__ {
		border: 1.5px solid #7367F0;
		padding: 20px;
		color: #7367F0;
		font-weight: bold;
	}
</style>
