<template>
  <div>
    <select-service-area-subarea
      ref="selectServiceAreaSubarea"
      :date.sync="current_day"
      :type-food.sync="typeMenu"
      @loadLocationPatients="loadLocationPatients"
    />
    <Statistics
      :series="series"
      :series2="series2"
      :series3="series3"
      :labels="labels"
      :is-busy="isBusy"
    />
    <b-row class="mb-2">
      <b-col cols="12">
        <div class="d-flex">
          <b-button
            id="btn1"
            class="btn__ active-btn mr-2"
            :disabled="isDisabled"
            @click="isActive('btn1')"
          >
            <span class="align-middle">Desayuno</span>
          </b-button>
          <b-button
            id="btn2"
            class="btn__ mr-2"
            :disabled="isDisabled"
            @click="isActive('btn2')"
          >
            <span class="align-middle">Almuerzo</span>
          </b-button>
          <b-button
            id="btn3"
            class="btn__ mr-2"
            :disabled="isDisabled"
            @click="isActive('btn3')"
          >
            <span class="align-middle">Cena</span>
          </b-button>
          <div class="d-flex align-items-center justify-content-end">
            <date-picker
              v-model="current_day"
              :attributes="attrs"
              mode="date"
              :model-config="modelConfig"
              is-required
              @input="loadOrderByAreaDate"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <b-form-input
                  :value="inputValue"
                  :disabled="isDisabled"
                  v-on="inputEvents"
                />
              </template>
            </date-picker>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col cols="12">
        <location-table
          :patients="patients2"
          :busy="isBusy2"
          :title-menu="titleMenu"
          :time-max-order="timeMaxOrder"
          @modalDiet="modalDiet"
          @editing="editing"
          @detail="detail"
        />
      </b-col>
    </b-row>
    <b-modal
      id="modal-diet-multiple"
      centered
      size="lg"
      :title="title"
      hide-footer
    >
      <diet-multiple
        :type-diets="typeDiets"
        :patient-ids="patientIds"
        :current-day="current_day"
        :is-form1="isForm1"
        :is-form2="isForm2"
        :type-diet-id="typeDietId"
        :snack-s="snack"
        :note-s="note"
      />
    </b-modal>
    <b-modal
      id="modal-edit-order"
      centered
      size="lg"
      title="Actulizar/Detalle orden"
      hide-footer
    >
      <order-edit-detail
        :type-diets="typeDiets"
        :current-day="current_day"
        :patient="patient"
        :is-detail="isDetail"
        :is-breakfast="isBreakfast"
        :is-luch="isLuch"
        :is-dinner="isDinner"
      />
    </b-modal>
  </div>
</template>

<script>
import {
 BRow, BCol, BButton, BModal, BFormInput,
} from 'bootstrap-vue'
import moment from 'moment'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import LocationTable from '../components/LocationTable'
import Statistics from '../components/Statistics'
import SelectServiceAreaSubarea from '@/components/SelectServiceAreaSubarea'
import DietMultiple from '../components/DietMultiple'
import OrderEditDetail from '../components/OrderEditDetail'

moment.locale('es')

export default {
	inject: ['orderRepository', 'patientsRepository', 'orderScheduleRepository'],
	components: {
		BRow,
		BCol,
		BModal,
		BButton,
		BFormInput,
		LocationTable,
		Statistics,
		SelectServiceAreaSubarea,
		DietMultiple,
		OrderEditDetail,
		DatePicker,
	},
	data() {
		return {
			series: [
				{
					name: 'Hoy',
					data: [],
				},
				{
					name: 'Ayer',
					data: [],
				},
			],
			series2: [],
			series3: [],
			typeDiets: [],
			labels: [],
			patients: [],
			patients2: [],
			patientIds: [],
			orderPatients: [],
			orderSchedules: [],
			isBusy: false,
			isBusy2: false,
			isDisabled: true,
			isBreakfast: false,
			isLuch: false,
			isDinner: false,
			isForm1: true,
			isForm2: false,
			isDetail: false,
			patient: null,
			areaId: null,
			timeMaxOrder: null,
			current_day: (moment()).format('YYYY-MM-DD'),
			yesterday: (moment().subtract(1, 'days'),
			moment().subtract(1, 'days')).format('YYYY-MM-DD'),
			title: 'Asignar desayuno',
			titleMenu: 'Desayunos',
			typeMenu: '1',
			patientUuid: this.$route.params.patientUuid,
			modelConfig: {
				type: 'string',
				mask: 'YYYY-MM-DD',
			},
			attrs: [
				{
					highlight: 'green',
					dates: new Date(),
				},
			],
			typeDietId: null,
			snack: false,
			note: null,
			cancel: false,
		}
	},
	async mounted() {
		const me = this
		await me.loadTypeDiets()
		await me.loadOrdersCurrentDay()
		await me.loadOrdersYesterday()
		await me.loadAllOrderSchedules()
		if (me.patientUuid) await me.loadPatient()
	},
	methods: {
		async loadOrdersCurrentDay() {
			const me = this
			try {
				const { data } = await me.orderRepository.getAll(me.current_day)
				me.fillMenuDay(data)
				me.fillTypeDiet(data)
			} catch (error) {
				me.series2 = []
				me.series3 = []
			}
		},
		async loadOrdersYesterday() {
			const me = this
			try {
				me.isBusy = true
				const { data } = await me.orderRepository.getAll(me.yesterday)
				me.fillMenuYesterday(data)
			} catch (error) {
				// console.log(error)
			} finally {
				me.isBusy = false
			}
		},
		async fillMenuDay(data) {
			const me = this
			const luches = []
			const breakfast = []
			const dinners = []
			if (data) {
				data.forEach(el => {
					if (el.menu.type_menu == '1') {
						breakfast.push(el)
					} else if (el.menu.type_menu == '2') {
						luches.push(el)
					} else {
						dinners.push(el)
					}
				})

				me.series2.push(breakfast.length)
				me.series2.push(luches.length)
				me.series2.push(dinners.length)

				me.series[0].data.push(
					breakfast.length,
					luches.length,
					dinners.length,
				)
			}
		},
		async fillMenuYesterday(data) {
			const me = this
			const luchesYesterday = []
			const breakfastYesterday = []
			const dinnersYesterday = []
			if (data) {
				data.forEach(el => {
					if (el.menu.type_menu == '1') {
						breakfastYesterday.push(el)
					} else if (el.menu.type_menu == '2') {
						luchesYesterday.push(el)
					} else {
						dinnersYesterday.push(el)
					}
				})

				me.series[1].data.push(
					breakfastYesterday.length,
					luchesYesterday.length,
					dinnersYesterday.length,
				)
			}
		},
		async fillTypeDiet(data) {
			const me = this
			const diets = []
			const typeDient = []

			if (data) {
				data.forEach(el => {
					me.typeDiets.forEach(diet => {
						if (diet.id == el.diet.id) {
							diets.push(diet)
						}
					})
				})

				diets.forEach(el => {
					const diet = typeDient.find(elem => elem.id == el.id)

					if (diet) {
						diet.types.push(el)
					} else {
						typeDient.push({
							name: el.name,
							id: el.id,
							types: [el],
						})
					}
				})

				typeDient.forEach(el => {
					me.labels.push(el.name)
					me.series3.push(el.types.length)
				})
			}
		},
		async loadTypeDiets() {
			const me = this
			try {
				const { data } = await me.orderRepository.getAllTypeDiet()
				me.typeDiets = data
			} catch (error) {
				// console.log(error)
			}
		},
		async reloadStatistics() {
			const me = this
			me.series[0].data = []
			me.series[1].data = []
			me.series2 = []
			me.series3 = []
			me.labels = []
			await me.loadOrdersCurrentDay()
			await me.loadOrdersYesterday()
		},
		loadLocationPatients(items, data) {
			const me = this
			me.patient = null
			me.patients = []
			me.areaId = data.areaId
			console.log(items)
			items.forEach(el => {
				if (el.patient) me.patients.push(el)
			})

			const diet = me.patients.length > 0 ? me.isDisabled = false : me.isDisabled = false
			me.loadOrderByAreaDate()
			me.listenSocket()
    },
		async loadPatient() {
			const me = this
			try {
				const { data } = await me.patientsRepository.find(me.patientUuid)
				me.patient = data
			} catch (error) {
				// console.log(error)
			}
		},
		editing(item) {
			const me = this
			if (me.patient) me.patient = null
			me.patient = item
			me.isDetail = false
			me.$bvModal.show('modal-edit-order')
		},
		detail(item) {
			const me = this
			if (me.patient) me.patient = null
			me.patient = item
			me.isDetail = true
			me.$bvModal.show('modal-edit-order')
		},
		dietMultiple() {
			const me = this
			me.$bvModal.show('modal-diet-multiple')
		},
		async modalDiet(patientIds, patient) {
			const me = this

			me.typeDietId = null
			me.snack = false
			me.note = null
			me.cancel = false

			let data = null
			try {
				data = await me.patientsRepository.getOrderSios(me.current_day, patient.location_patient.location_id, patient.diet)

				if (data && data.data) {
					data = data.data
					console.log(data.data)

					if (data.data?.is_cancelled ?? false) {
						me.cancel = true
					}

					if (data.data.diet_type) {
						me.typeDietId = data.data.diet_type ?? null
					} else {
						me.typeDietId = null
					}

					if (data.data.snack) {
						me.snack = data.data.snack ?? false
					} else {
						me.snack = false
					}

					if (data.data.note) {
						me.note = data.data.note ?? null
					} else {
						me.note = null
					}
				}
			} catch (e) {
				console.log(e)
			}

			me.patientIds = patientIds
			if (!me.cancel) {
				me.$bvModal.show('modal-diet-multiple')
			} else {
				me.$bvToast.toast('La dieta, ya fue cancelada', {
					title: 'Error',
					variant: 'danger',
					solid: true,
				})
			}
		},
		async isActive(id) {
			const me = this
			if (id == 'btn1') {
				me.isForm1 = true
				me.isForm2 = false
				me.title = 'Asignar desayuno'
				me.titleMenu = 'Desayunos'
				me.isBreakfast = true
				me.isLuch = false
				me.isDinner = false
				me.typeMenu = '1'
				const orderSchedule = me.orderSchedules?.find(el => el.menu_type == '1')
				me.timeMaxOrder = orderSchedule ? orderSchedule.time : null
			} else if (id == 'btn2') {
				me.isForm1 = false
				me.isForm2 = true
				me.title = 'Asignar almuerzo'
				me.titleMenu = 'Almuerzos'
				me.isBreakfast = false
				me.isLuch = true
				me.isDinner = false
				me.typeMenu = '2'
				const orderSchedule = me.orderSchedules?.find(el => el.menu_type == '2')
				me.timeMaxOrder = orderSchedule ? orderSchedule.time : null
			} else {
				me.isForm1 = false
				me.isForm2 = false
				me.title = 'Asignar cena'
				me.titleMenu = 'Cenas'
				me.isBreakfast = false
				me.isLuch = false
				me.isDinner = true
				me.typeMenu = '3'
				const orderSchedule = me.orderSchedules?.find(el => el.menu_type == '3')
				me.timeMaxOrder = orderSchedule ? orderSchedule.time : null
			}

			await this.$refs.selectServiceAreaSubarea.loadLocationPatient(me.typeMenu, me.current_day)

			// await me.loadOrderByAreaDate()

			const btns = document.querySelector('.active-btn')
			const btn = document.getElementById(id)

			if (btns) btns.classList.remove('active-btn')
      btn.classList.add('active-btn')
		},
		async loadOrderByAreaDate() {
			const me = this
			me.patients2 = []
			me.orderPatients = []
			try {
				me.isBusy2 = true
				const { data } = await me.orderRepository.getAllByAreaDate(me.areaId, me.current_day)
				me.orderPatients = data
				me.isBreakfast = true
				me.loadPatientsByMenus(me.typeMenu)
			} catch (error) {
				me.patients.forEach(el => {
					me.patients2.push({
						...el,
						diet: me.typeMenu,
					})
				})
			} finally {
				me.isBusy2 = false
			}
		},
		loadPatientsByMenus(type) {
			const me = this
			me.patients2 = []
			const orderPatients1 = []
			if (me.orderPatients.length > 0) {
				me.patients.forEach(el => {
					const menu = me.orderPatients.find(x => x.patient_id == el.patient.id && x.menu.type_menu == type)

					if (menu) {
						orderPatients1.push({
							...el,
							status: true,
							diet: menu.menu.type_menu,
							arrival_at: menu.arrival_at,
						})
					} else {
						orderPatients1.push({
								...el,
							status: false,
							diet: type,
							arrival_at: null,
						})
					}
				})
				me.patients2 = orderPatients1
			} else {
				me.patients.forEach(el => {
					me.patients2.push({
						...el,
						diet: me.typeMenu,
					})
				})
			}
		},
		refreshPatientsOrder() {
			const me = this
			me.loadOrderByAreaDate()
			me.reloadStatistics()
		},
		listenSocket() {
			const me = this
			window.Echo.channel(`menu-order-${me.areaId}`)
				.listen(
					'.menu.order.created',
					e => {
						me.refreshPatientsOrder()
					},
				)
				.listen(
					'.menu.order.status',
					e => {
						me.refreshPatientsOrder()
					},
				)
		},
		async loadAllOrderSchedules() {
			const me = this
			try {
				const { data } = await me.orderScheduleRepository.getAll()
				me.orderSchedules = data
				const orderSchedule = me.orderSchedules?.find(el => el.menu_type == '1')
				me.timeMaxOrder = orderSchedule ? orderSchedule.time : null
			} catch (error) {
				// console.log(error)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.select {
	width: 30%;
}

.card__ {
	box-shadow: 0 5px 11px 0 rgba(145, 144, 144, 0.3),
		0 4px 15px 0 rgba(133, 132, 132, 0.15);
}

.title {
	font-size: 18px;
	font-weight: 700;
	color: #636363;
}

.title2 {
	font-size: 42px;
	font-weight: 700;
	color: #636363;
}

.title3 {
	font-size: 16px;
	font-weight: 700;
	color: #636363;
}

.title4 {
	font-size: 14px;
	color: #636363;
	font-weight: 700;
	margin-bottom: 5px;
}

.title5 {
	font-size: 14px;
	color: #636363;
}

.box2 {
	border: 1px solid grey;
	padding: 1rem;
	border-radius: 0.6rem;
}

.card__title {
	font-size: 42px;
	font-weight: 700;
	color: #636363;
	position: relative;
	left: -15px;
}

.card__subtitle {
	font-size: 14px;
	color: #636363;
	position: relative;
	left: -15px;
}

.iconCalendar {
	position: absolute;
	left: 90px;
	top: -8px;
	color: #b6b5b5;
}
.btn__ {
	background-color: #fff !important;
	border: 1px solid #7367f0 !important;
	padding: 8px !important;
	color: #7367f0 !important;
	border-radius: 0.5rem !important;
	width: 20% !important;
	text-align: center !important;
}

.btn__.active-btn {
	background-color: #7367f0 !important;
	color: #fff !important;
}
</style>
