<template>
  <div>
    <b-row>
      <b-col md="9">
        <b-row>
          <b-col cols="12">
            <div class="d-flex align-items-center mb-2">
              <div class="title3 mr-1">
                Dieta:
              </div>
              <div class="w-100">
                <v-select
                  v-model="formData.diet_type_id"
                  label="name"
                  placeholder="Seleccione tipo de dieta"
                  :disabled="isDisabled || isDetail"
                  :options="typeDiets"
                  :reduce="option => option.id"
                >
                  <div slot="no-options">
                    No hay opciones
                  </div>
                </v-select>
                <span
                  v-if="$v.formData.diet_type_id.$error && !$v.formData.diet_type_id.required"
                  class="help-block text-danger"
                >Campo requerido</span>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row v-if="!breakfast.dishes && !breakfast.hotDrinks && !breakfast.coldDrinks">
          <b-col
            cols="12"
            class="d-flex justify-content-center"
          >
            <div class="text-center">
              <div class="box">
                No se han planeado platos ni acompañamientos para la fecha seleccionada
              </div>
            </div>
          </b-col>
        </b-row>
        <div v-else>
          <b-row v-if="breakfast.dishes">
            <b-col cols="12">
              <div class="title">
                Platos Principales
              </div>
            </b-col>
            <b-col
              v-if="!isDisabled && !isDetail"
              cols="12"
            >
              <b-form-group>
                <v-select
                  v-model="breakfastData1.dishes"
                  multiple
                  :options="breakfast.dishes"
                  label="name"
                  placeholder="Seleccione platos"
                  :disabled="isDisabled"
                  @input="setSelected"
                >
                  <div slot="no-options">
                    No hay opciones
                  </div>
                  <template #option="{ name, image }">
                    <template v-if="image !== null">
                      <img
                        :src="loadImage(image)"
                        :alt="name"
                        width="10%"
                        height="30px"
                      >
                    </template>
                    <template v-else>
                      <img
                        src="@/assets/images/default.png"
                        :alt="name"
                        width="10%"
                      >
                    </template>
                    <span> {{ name }}</span>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="breakfastData1.dishes">
            <b-col
              v-for="(item, index) in breakfastData1.dishes"
              :key="index"
              cols="4"
            >
              <card-dishe :item="item" />
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col
              v-if="isDisabled"
              cols="12"
              class="d-flex justify-content-center"
            >
              <div class="title2">
                No existen registros
              </div>
            </b-col>
          </b-row>
          <b-row v-if="breakfast.hotDrinks">
            <b-col cols="12">
              <div class="title">
                Bebidas Calientes
              </div>
            </b-col>
            <b-col
              v-if="!isDisabled && !isDetail"
              cols="12"
            >
              <b-form-group>
                <v-select
                  v-model="breakfastData1.hotDrinks"
                  multiple
                  :options="breakfast.hotDrinks"
                  label="name"
                  placeholder="Seleccione bebidas calientes"
                  :disabled="isDisabled"
                  @input="setSelected"
                >
                  <div slot="no-options">
                    No hay opciones
                  </div>
                  <template #option="{ name, image }">
                    <template v-if="image !== null">
                      <img
                        :src="loadImage(image)"
                        :alt="name"
                        width="10%"
                        height="30px"
                      >
                    </template>
                    <template v-else>
                      <img
                        src="@/assets/images/default.png"
                        :alt="name"
                        width="10%"
                      >
                    </template>
                    <span> {{ name }}</span>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="breakfastData1.hotDrinks">
            <b-col
              v-for="(item, index) in breakfastData1.hotDrinks"
              :key="index"
              cols="4"
            >
              <card-dishe :item="item" />
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col
              v-if="isDisabled"
              cols="12"
              class="d-flex justify-content-center"
            >
              <div class="title2">
                No existen registros
              </div>
            </b-col>
          </b-row>
          <b-row v-if="breakfast.coldDrinks">
            <b-col cols="12">
              <div class="title">
                Bebidas Frías
              </div>
            </b-col>
            <b-col
              v-if="!isDisabled && !isDetail"
              cols="12"
            >
              <b-form-group>
                <v-select
                  v-model="breakfastData1.coldDrinks"
                  multiple
                  :options="breakfast.coldDrinks"
                  label="name"
                  placeholder="Seleccione bebidas frías"
                  :disabled="isDisabled"
                  @input="setSelected"
                >
                  <div slot="no-options">
                    No hay opciones
                  </div>
                  <template #option="{ name, image }">
                    <template v-if="image !== null">
                      <img
                        :src="loadImage(image)"
                        :alt="name"
                        width="10%"
                        height="30px"
                      >
                    </template>
                    <template v-else>
                      <img
                        src="@/assets/images/default.png"
                        :alt="name"
                        width="10%"
                      >
                    </template>
                    <span> {{ name }}</span>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="breakfastData1.coldDrinks">
            <b-col
              v-for="(item, index) in breakfastData1.coldDrinks"
              :key="index"
              cols="4"
            >
              <card-dishe :item="item" />
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col
              v-if="isDisabled"
              cols="12"
              class="d-flex justify-content-center"
            >
              <div class="title2">
                No existen registros
              </div>
            </b-col>
          </b-row>
        </div>
        <b-row>
          <b-col
            cols="12"
            class="mb-1"
          >
            <b-form-group
              label="Hora de entrega"
              label-for="hour"
            >
              <b-form-input
                v-model="formData.hour"
                :disabled="isDisabled || isDetail"
                class="form-control"
                type="time"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="mb-1"
          >
            <b-form-checkbox
              v-model="formData.snack"
              :disabled="isDisabled || isDetail"
            >
              Solicitar merienda
            </b-form-checkbox>
          </b-col>
          <b-col
            cols="12"
            class="mb-2"
          >
            <b-form-textarea
              v-model="formData.observations"
              :disabled="isDisabled || isDetail"
              placeholder="Observaciones"
              rows="3"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-if="!isDisabled && !isDetail"
            cols="12"
            class="text-center mt-1 mb-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              :disabled="isBussy || $v.$invalid"
              @click="save"
            >
              <b-spinner
                v-if="isBussy"
                small
              />
              <v-icon
                v-else
                name="save"
              />
              {{ recordBreakfast == null ? 'Enviar Pedido' : 'Actualizar Pedido' }}
            </b-button>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="3">
        <Timeline :record="recordBreakfast" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BSpinner,
  BButton,
  BFormTextarea,
  BFormCheckbox,
  BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { required } from 'vuelidate/lib/validators'
import { API_ROUTES } from '../../config/routes/api'
import CardDishe from '../CardDishe'
import Timeline from '../Timeline'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BSpinner,
    BButton,
    BFormTextarea,
    BFormCheckbox,
    vSelect,
    CardDishe,
    BFormInput,
    Timeline,
  },
  directives: {
    Ripple,
  },
  props: {
    isBussy: {
      type: Boolean,
      default: null,
    },
    breakfast: {
      type: Object,
      default: null,
    },
    breakfastData: {
      type: Object,
      default: null,
    },
    typeDiets: {
      type: Array,
      default: null,
    },
    recordBreakfast: {
      type: Object,
      default: null,
    },
    isDetail: {
			type: Boolean,
			default: false,
		},
  },
  data() {
    const me = this
    return {
      breakfastData1: {
        dishes: [],
        hotDrinks: [],
        coldDrinks: [],
      },
      formData: {
        menu_items: [],
        menu_id: me.breakfast != null ? me.breakfast.id : null,
        diet_type_id: null,
        snack: false,
        observations: '',
        hour: '07:00',
      },
      isDisabled: false,
    }
  },
  validations: {
    formData: {
      menu_items: {
        required,
      },
      diet_type_id: {
        required,
      },
    },
  },
  watch: {
    recordBreakfast(newData) {
      const me = this
      me.isDisabled = newData.departure_at != '' ? me.isDisabled = true : false
      me.formData = { ...me.formData, ...newData }
    },
    breakfastData(newData) {
      const me = this
      me.breakfastData1 = { ...me.breakfastData1, ...newData }
    },
  },
  mounted() {
    const me = this
    me.isDisabled = me.recordBreakfast && me.recordBreakfast.departure_at != '' ? me.isDisabled = true : false
    me.formData = { ...me.formData, ...me.recordBreakfast }
    me.breakfastData1 = { ...me.breakfastData1, ...me.breakfastData }
  },
  methods: {
    save() {
      const me = this
      if (me.formData.observations == '') delete me.formData.observations
      me.$emit('formSubmitted', me.formData)
    },
    setSelected() {
      const me = this
      me.formData.menu_items = []
      if (me.breakfastData1.dishes.length > 1) {
        me.breakfastData1.dishes.splice(0, 1)
      }
      me.breakfastData1.dishes.forEach(el => me.formData.menu_items.push({ food_id: el.id }))
      me.breakfastData1.hotDrinks.forEach(el => me.formData.menu_items.push({ food_id: el.id }))
      me.breakfastData1.coldDrinks.forEach(el => me.formData.menu_items.push({ food_id: el.id }))
      /* me.formData.menu_items = JSON.stringify(me.formData.menu_items) */
    },
    loadImage(image) {
      return API_ROUTES.images.image + image
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 8px;
  color: #5E5878;
}

.title2 {
  font-size: 12px;
  margin-bottom: 5px;
  color: #5E5878;
}

.img__ {
  height: 90px !important;
  margin-bottom: 5px !important;
}

.box {
  border: 2.5px solid rgb(248, 102, 102);
  border-radius: .3rem;
  padding: 8px;
  color: #636363;
  font-size: 14px;
  margin-bottom: 15px;
  font-weight: bold;
}
</style>
