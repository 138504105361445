<template>
  <b-card
    no-body
    class="overflow-hidden"
  >
    <b-row>
      <b-col
        cols="12"
        class="text-left pl-3 mt-1"
      >
        <h2 class="title_menu">
          {{ titleMenu }}
        </h2>
        <h6 v-if="timeMaxOrder">
          Hora máxima para asignar dietas: <strong>{{ timeMaxOrder }}</strong>
        </h6>
      </b-col>
      <b-col
        lg="4"
        class="align-self-center pl-3"
      >
        <b-button
          variant="success"
          size="sm"
          class="bg-daren-2"
          :disabled="isDisabled"
          @click.prevent="newOrder"
        >
          <feather-icon
            icon="PlusIcon"
          />
          Asignar
        </b-button>
      </b-col>
      <b-col
        md="8"
        class="my-1 pr-3"
      >
        <b-form-group
          label="Buscar"
          label-cols-sm="3"
          label-align-sm="right"
          label-for="filterInput"
          class="mb-0 w-75 float-right"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder=""
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Limpiar
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-table
          ref="selectableTable"
          striped
          hover
          responsive
          small
          show-empty
          selectable
          :select-mode="'multi'"
          :fields="fields"
          :items="patients"
          :filter="filter"
          :per-page="dataPerPage"
          :current-page="pagination.currentPage"
          :busy="busy"
          :filter-included-fields="filterOn"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          selected-variant="success"
          @filtered="onFiltered"
          @row-selected="onRowSelected"
        >
          <template #table-busy>
            <div class="text-center text-success my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Cargando datos...</strong>
            </div>
          </template>
          <template #empty>
            <h6 class="text-center py-2">
              No hay registros
            </h6>
          </template>
          <template #head(selected)>
            <b-form-checkbox
              v-model="statusAll"
              @input="selectAllRows"
            />
          </template>
          <template #cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <div class="d-flex align-items-center justify-content-center">
                <b-icon-check-2-square
                  class="icon_check"
                  aria-hidden="true"
                />
              </div>
            </template>
            <template v-else>
              <span aria-hidden="true">
                &nbsp;
              </span>
            </template>
          </template>
          <template #cell(patient)="data">
            {{ data.item.patient.first_name + ' ' + data.item.patient.last_name }}
          </template>
          <template #cell(diet)="data">
            <b-badge
              v-if="data.item.diet"
              variant="info"
            >
              {{ typeDiet(data.item.diet) }}
            </b-badge>
          </template>
          <template #cell(status)="data">
            <b-badge :variant="typeVariant(data.item)">
              {{ typeStatus(data.item) }}
            </b-badge>
          </template>
          <template #cell(actions)="data">
            <b-button
              v-if="data.item.status"
              size="sm"
              variant="primary"
              class="btn-icon mr-25"
              @click.prevent="() => $emit('detail', data.item.patient)"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
            <b-button
              v-if="data.item.status"
              variant="warning"
              class="btn-icon"
              size="sm"
              @click.prevent="() => $emit('editing', data.item.patient)"
            >
              <feather-icon
                icon="EditIcon"
              />
            </b-button>
          </template>
        </b-table>
      </b-col>
      <b-col
        md="6"
        class="pl-3"
      >
        <b-form-group
          class="mb-0"
        >
          <label class="d-inline-block text-sm-left mr-50">Páginas</label>
          <b-form-select
            v-model="pagination.perPage"
            size="sm"
            :options="pagination.pageOptions"
            class="w-50"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="6"
        class="pr-3 pb-2"
      >
        <b-pagination
          v-model="pagination.currentPage"
          :total-rows="pagination.totalRows"
          :per-page="pagination.perPage"
          align="right"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BTable,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BPagination,
  BSpinner,
  BCard,
  BFormCheckbox,
  BFormSelect,
  BIconCheck2Square,
  BBadge,
} from 'bootstrap-vue'

export default {
	inject: ['patientsRepository'],
	components: {
    BTable,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BPagination,
    BCard,
    BSpinner,
    BIconCheck2Square,
    BFormCheckbox,
    BFormSelect,
    BBadge,
  },
  props: {
    patients: {
      type: Array,
      default: () => [],
    },
    busy: {
      type: Boolean,
      default: false,
    },
    titleMenu: {
      type: String,
      default: null,
    },
    timeMaxOrder: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      filter: null,
      pagination: {
        perPage: 10,
        pageOptions: [10, 20, 50],
        totalRows: 1,
        currentPage: 1,
      },
      filterOn: [],
      sortDesc: false,
      statusAll: false,
      sortDirection: 'asc',
      fields: [
        'selected',
        {
          key: 'name',
          label: 'Cama',
          sortable: true,
        },
        {
          key: 'patient',
          label: 'Paciente',
          sortable: true,
        },
        {
          key: 'patient.document_number',
          label: 'N° de documento',
          sortable: true,
        },
        {
          key: 'diet',
          label: 'Dieta',
        },
        {
          key: 'status',
          label: 'Estado',
        },
        { key: 'actions', label: 'Accion' },
      ],
      selected: [],
      isDisabled: true,
    }
  },
  computed: {
    dataPerPage() {
      const me = this
      return me.paginate ? me.pagination.perPage : me.patients.length
    },
  },
  mounted() {
    const me = this
    me.pagination.totalRows = me.patients.length
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.pagination.currentPage = 1
    },
    onRowSelected(items) {
      const me = this
      me.selected = []

      me.patients.forEach((el, index) => {
        if (el.status) {
          me.$refs.selectableTable.unselectRow(index)
        }
      })

      items.forEach(el => {
        if (!el.status) me.selected.push(el)
      })

      if (me.selected.length == me.patients.length) {
        me.statusAll = true
      }

      if (me.selected.length == 0) me.statusAll = false

      if (me.selected.length > 0) {
        me.isDisabled = false
      } else {
        me.isDisabled = true
      }
    },
    selectAllRows() {
      const me = this

      if (me.statusAll) {
        me.$refs.selectableTable.selectAllRows()
      } else {
        me.$refs.selectableTable.clearSelected()
      }
    },
    async newOrder() {
      const me = this
      const patient_ids = []
      me.selected.forEach(el => {
        patient_ids.push({ patient_id: el.patient.id })
      })
			let patient = null
			if (patient_ids.length === 1) {
				patient = me.patients.find(el => el.patient.id === patient_ids[0].patient_id)
			}

      me.$emit('modalDiet', patient_ids, patient)
    },
    typeDiet(type) {
      let text = null
      if (type && type == 1) {
        text = 'Desayuno'
      } else if (type && type == 2) {
        text = 'Almuerzo'
      } else if (type && type == 3) {
        text = 'Cena'
      }

      return text
    },
    typeVariant(item) {
      let variant = null
      if (item.status == 1 && item.arrival_at !== '') {
        variant = 'success'
      } else if (item.status == 1) {
        variant = 'secondary'
      } else {
        variant = 'danger'
      }
      return variant
    },
    typeStatus(item) {
      let text = null
      if (item.status == 1 && item.arrival_at !== '') {
        text = 'Entregado'
      } else if (item.status == 1) {
        text = 'Asignada'
      } else {
        text = 'Sin asignar'
      }
      return text
    },
  },
}
</script>
<style lang="scss" scoped>
.icon_check {
  color: var(--black-color);
  font-size: 20px;
}
.title_menu {
	font-size: 22px;
	font-weight: 700;
	color: #636363;
}
</style>
