<template>
  <div class="">
    <div class="d-flex align-items-center justify-content-end">
      <v-icon
        name="calendar-alt"
        class="mr-1"
        scale="1"
      />
      <date-picker
        v-model="date"
        :attributes="attrs"
        mode="date"
        :model-config="modelConfig"
        :available-dates="{ start: new Date, end: null }"
        @input="doSomethingOnChange"
      >
        <template v-slot="{ inputValue, inputEvents }">
          <b-form-input
            :value="inputValue"
            v-on="inputEvents"
          />
        </template>
      </date-picker>
    </div>
    <b-row>
      <b-col md="12">
        <div v-if="isBussy">
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-50" />
            <strong>Cargando...</strong>
          </div>
        </div>
        <div v-else>
          <div v-if="isEmpty">
            <div class="text-center mb-5 mt-5 pl-5 pr-5">
              <div class="box">
                No existe menú programado para la fecha
                <span>{{ date }}</span>
              </div>
            </div>
          </div>
          <div v-else>
            <div v-if="isForm1">
              <breakfast-form
                :breakfast="breakfast"
                :type-diets="typeDiets"
                :patients="dataPatients"
                :breakfast-data="breakfastData"
                :record-breakfast="recordBreakfast"
                :is-bussy="isBussy2"
                :type-diet-id="typeDietId"
                :snack-s="snackS"
                :note-s="noteS"
                @formSubmitted="formSubmitted"
              />
            </div>
            <div v-else-if="isForm2">
              <luch-form
                :luch="luch"
                :type-diets="typeDiets"
                :patients="dataPatients"
                :luch-data="luchData"
                :record-luch="recordLuch"
                :is-bussy="isBussy2"
                :type-diet-id="typeDietId"
                :snack-s="snackS"
                :note-s="noteS"
                @formSubmitted="formSubmitted"
              />
            </div>
            <div v-else>
              <dinner-form
                :dinner="dinner"
                :type-diets="typeDiets"
                :patients="dataPatients"
                :dinner-data="dinnerData"
                :record-dinner="recordDinner"
                :is-bussy="isBussy2"
                :type-diet-id="typeDietId"
                :snack-s="snackS"
                :note-s="noteS"
                @formSubmitted="formSubmitted"
              />
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
 BRow, BCol, BSpinner, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { v4 as uuidv4 } from 'uuid'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import BreakfastForm from './addFormDietMultiple/BreakfastForm'
import LuchForm from './addFormDietMultiple/LuchForm'
import DinnerForm from './addFormDietMultiple/DinnerForm'

export default {
	inject: ['menuRepository', 'orderRepository'],
	components: {
		BRow,
		BCol,
		BSpinner,
		BFormInput,
		BreakfastForm,
		LuchForm,
		DinnerForm,
		DatePicker,
	},
	directives: {
		Ripple,
	},
	props: {
		typeDiets: {
			type: Array,
			default: null,
		},
		patientIds: {
			type: Array,
			default: () => [],
		},
		currentDay: {
			type: String,
			default: null,
		},
		isForm1: {
			type: Boolean,
			default: true,
		},
		isForm2: {
			type: Boolean,
			default: false,
		},
		typeDietId: {
			type: String,
			default: null,
		},
		snackS: {
			type: Boolean,
			default: false,
		},
		noteS: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			date: null,
			isBussy: false,
			isBussy2: false,
			breakfast: {
				id: null,
				dishes: null,
				hotDrinks: null,
				coldDrinks: null,
			},
			luch: {
				id: null,
				dishes: null,
				hotDrinks: null,
				coldDrinks: null,
			},
			dinner: {
				id: null,
				dishes: null,
				hotDrinks: null,
				coldDrinks: null,
			},
			breakfastData: null,
			luchData: null,
			dinnerData: null,
			recordBreakfast: null,
			recordLuch: null,
			recordDinner: null,
			isEmpty: true,
      dataPatients: [],
			modelConfig: {
				type: 'string',
				mask: 'YYYY-MM-DD',
			},
			attrs: [
				{
					highlight: 'green',
					dates: new Date(),
				},
			],
		}
	},
  mounted() {
    const me = this
    me.date = me.currentDay
    me.loadMenuByDate(me.date)
  },
	methods: {
		async loadMenuByDate(date) {
			const me = this
			try {
				me.isBussy = true
				let breakfasts = null
				let luches = null
				let dinners = null

				const res = await me.menuRepository.getAllMenuByDate(date)
				me.isEmpty = false
				const { data } = res

				data.forEach(el => {
					if (el.type_menu == '1') {
						breakfasts = el
						me.breakfast.id = el.id
					} else if (el.type_menu == '2') {
						luches = el
						me.luch.id = el.id
					} else {
						dinners = el
						me.dinner.id = el.id
					}
				})
				me.fillBreakfasts(breakfasts)
				me.fillLuches(luches)
				me.fillDinners(dinners)
			} catch (error) {
				me.isEmpty = true
				// console.log(error)
			} finally {
				me.isBussy = false
			}
		},
		doSomethingOnChange() {
			const me = this
			me.loadMenuByDate(me.date)
		},
		fillBreakfasts(data) {
			const me = this
			if (data) {
				const dishes = []
				const hotDrinks = []
				const coldDrinks = []
				data.items.forEach(x => {
					if (x.accompaniment == '0') {
						dishes.push(x)
					} else if (
						x.type_accompaniment !== null &&
						x.type_accompaniment == '1'
					) {
						coldDrinks.push(x)
					} else {
						hotDrinks.push(x)
					}
				})
				const d =
					dishes.length > 0
						? (me.breakfast.dishes = dishes)
						: (me.breakfast.dishes = null)
				const hd =
					hotDrinks.length > 0
						? (me.breakfast.hotDrinks = hotDrinks)
						: (me.breakfast.hotDrinks = null)
				const cd =
					coldDrinks.length > 0
						? (me.breakfast.coldDrinks = coldDrinks)
						: (me.breakfast.coldDrinks = null)
			} else {
				me.breakfast.dishes = null
				me.breakfast.hotDrinks = null
				me.breakfast.coldDrinks = null
			}
		},
		fillLuches(data) {
			const me = this
			if (data) {
				const dishes = []
				const hotDrinks = []
				const coldDrinks = []
				data.items.forEach(x => {
					if (x.accompaniment == '0') {
						dishes.push(x)
					} else if (
						x.type_accompaniment !== null &&
						x.type_accompaniment == '1'
					) {
						coldDrinks.push(x)
					} else {
						hotDrinks.push(x)
					}
				})
				const d =
					dishes.length > 0
						? (me.luch.dishes = dishes)
						: (me.luch.dishes = null)
				const hd =
					hotDrinks.length > 0
						? (me.luch.hotDrinks = hotDrinks)
						: (me.luch.hotDrinks = null)
				const cd =
					coldDrinks.length > 0
						? (me.luch.coldDrinks = coldDrinks)
						: (me.luch.coldDrinks = null)
			} else {
				me.luch.dishes = null
				me.luch.hotDrinks = null
				me.luch.coldDrinks = null
			}
		},
		fillDinners(data) {
			const me = this
			if (data) {
				const dishes = []
				const hotDrinks = []
				const coldDrinks = []
				data.items.forEach(x => {
					if (x.accompaniment == '0') {
						dishes.push(x)
					} else if (
						x.type_accompaniment !== null &&
						x.type_accompaniment == '1'
					) {
						coldDrinks.push(x)
					} else {
						hotDrinks.push(x)
					}
				})
				const d =
					dishes.length > 0
						? (me.dinner.dishes = dishes)
						: (me.dinner.dishes = null)
				const hd =
					hotDrinks.length > 0
						? (me.dinner.hotDrinks = hotDrinks)
						: (me.dinner.hotDrinks = null)
				const cd =
					coldDrinks.length > 0
						? (me.dinner.coldDrinks = coldDrinks)
						: (me.dinner.coldDrinks = null)
			} else {
				me.dinner.dishes = null
				me.dinner.hotDrinks = null
				me.dinner.coldDrinks = null
			}
		},
		async formSubmitted(data) {
			const me = this
			try {
				me.isBussy2 = true
				const uuid = data.id ? data.id : uuidv4()
				const option = data.id ? 'update' : 'save'

				await me.orderRepository[option](uuid, {
					...data,
					date: me.date,
					menu_items: JSON.stringify(data.menu_items),
					patient_ids: JSON.stringify(me.patientIds),
				})
				me.$bvToast.toast('Registro guardado con éxito', {
					title: 'Éxito',
					toaster: 'b-toaster-bottom-right',
					variant: 'success',
					solid: true,
					appendToast: true,
				})
				me.$bvModal.hide('modal-diet-multiple')
			} catch (error) {
				const { message } = error.response.data

				me.$bvToast.toast(message, {
					title: 'Error.',
					toaster: 'b-toaster-bottom-right',
					variant: 'danger',
					solid: true,
					appendToast: true,
				})
			} finally {
				me.isBussy2 = false
			}
		},
		fillMenuDataByTypeMenu(data, type) {
			const me = this
			let dishes = []
			let hotDrinks = []
			let coldDrinks = []
			const menu_items = []
			let record = null

			if (data) {
				data.items.forEach(el => menu_items.push({ food_id: el.id }))
				record = {
					menu_items: menu_items,
					menu_id: data.menu_id,
					patient_id: data.patient_id,
					diet_type_id: data.diet_type_id,
					snack: data.snack == '1',
					observations: data.observations,
					hour: data.hour,
					departure_at: data.departure_at,
					arrival_at: data.arrival_at,
					id: data.id,
				}
				data.items.forEach(x => {
					if (x.accompaniment == '0') {
						dishes.push(x)
					} else if (
						x.type_accompaniment !== null &&
						x.type_accompaniment == '1'
					) {
						coldDrinks.push(x)
					} else {
						hotDrinks.push(x)
					}
				})
			} else {
				dishes = []
				hotDrinks = []
				coldDrinks = []
			}

			if (type == '1') {
				me.breakfastData = {
					dishes: dishes,
					hotDrinks: hotDrinks,
					coldDrinks: coldDrinks,
				}
				me.recordBreakfast = record
			} else if (type == '2') {
				me.luchData = {
					dishes: dishes,
					hotDrinks: hotDrinks,
					coldDrinks: coldDrinks,
				}
				me.recordLuch = record
			} else {
				me.dinnerData = {
					dishes: dishes,
					hotDrinks: hotDrinks,
					coldDrinks: coldDrinks,
				}
				me.recordDinner = record
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.title {
	font-size: 18px;
	font-weight: 700;
	color: #636363;
}

.title2 {
	font-size: 14px;
	color: #636363;
}

.box {
	border: 1px solid rgb(248, 102, 102);
	border-radius: 0.3rem;
	padding: 8px;
	color: #636363;
	font-size: 16px;
	position: relative;
	top: 30px;
}

.box span {
	color: rgb(248, 102, 102);
	font-weight: bold;
}
</style>
